import momentUtil from '@/utils/MomentUtil';

export {
  registerRouteChange, setUser, logout,
};

function registerRouteChange() {
  // eslint-disable-next-line no-undef
  if (typeof formbricks !== 'undefined') {
    // eslint-disable-next-line no-undef
    formbricks.registerRouteChange();
  }
}

function setUser(user) {
  // eslint-disable-next-line no-undef
  if (typeof formbricks !== 'undefined') {
    // eslint-disable-next-line no-undef
    formbricks.logout();

    // eslint-disable-next-line no-undef
    formbricks.init({
      environmentId: process.env.VUE_APP_FORMBRICKS_ENV_ID,
      apiHost: 'https://app.formbricks.com',
      userId: user.id,
      attributes: {
        Email: user.email,
        'First Name': user.first_name,
        'Last Name': user.last_name,
        'Business Id': user.business_id,
        'Created At (Days Ago)': Math.round(momentUtil.duration(Date.now() - momentUtil(user.created_at, null))
          .asDays()),
      },
    });
  }
}

function logout() {
  // eslint-disable-next-line no-undef
  if (typeof formbricks !== 'undefined') {
    // eslint-disable-next-line no-undef
    formbricks.logout();
  }
}
