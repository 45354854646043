import store from '@/store';
import Tour from '@/constants/Tour';
import moment from 'moment/moment';

function isFinished(status) {
  return [Tour.STATUS_COMPLETED, Tour.TECHNICAL_ISSUE, Tour.NO_SHOW].includes(status);
}

async function getTourByDateAndAssignee(schedule, assigneeId, newTourAt = null) {
  const payload = {
    scheduleId: schedule.id,
    assigneeId,
  };

  if (newTourAt) {
    payload.tourAt = newTourAt;
  }

  const assigneeTour = await store.dispatch('Schedule/getAssigneeTour', payload);

  if (assigneeTour && assigneeTour.id === assigneeId) {
    return assigneeTour;
  }
  return {};
}

function isPaidToursCloseToMaxBudget(paidToursCount, maxBudgetTours) {
  const tourWarningThreshold = 2;
  return maxBudgetTours - paidToursCount <= tourWarningThreshold;
}

/**
 * @param tourStatus String
 * @param localTzTourDate Moment
 * @param isSelfShowing Boolean
 * @param isOpenHouse Boolean
 * @returns String
 */
function getTourStatusDescription(tourStatus, localTzTourDate, isSelfShowing, isOpenHouse) {
  const currentDate = moment();
  const formattedTourAt = localTzTourDate.toDisplayFormatMonthWithDayAndShortTimeFormat();
  const isTourInFuture = localTzTourDate.isAfter(currentDate);
  let description = '';

  const issueTourStatusDescriptionMap = {
    [Tour.STATUS_CANCELLED_BY_ADMIN]: 'Cancelled by Showdigs',
    [Tour.STATUS_CANCELLED_BY_AGENT]: 'Access Issue',
    [Tour.STATUS_CANCELLED_BY_LEAD]: 'Prospect cancelled',
    [Tour.STATUS_CANCELLED_BY_LEAD_RESCHEDULED]: 'Prospect cancelled - Rescheduled',
    [Tour.STATUS_CANCELLED_BY_PM]: 'Cancelled by PM',
    [Tour.STATUS_CANCELLED_BY_TECHNICAL_ISSUE]: 'Access Issue',
    [Tour.STATUS_CANCELLED_NO_LEAD_CONFIRMATION]: 'Prospect did not confirm',
    [Tour.STATUS_CANCELLED_RESCHEDULED]: 'Rescheduled',
    [Tour.STATUS_CANCELLED_SHOWING_PAUSED]: 'Cancelled - Showings paused',
    [Tour.STATUS_CANCELLED_SHOWING_STOPPED]: 'Cancelled - Showing stopped or changed',
    [Tour.STATUS_CANCELLED_SHOWING_WINDOWS_CHANGED]: 'Cancelled - Showing windows changed',
    [Tour.STATUS_CANCELLED_SURRENDERED]: 'Upcoming',
    [Tour.STATUS_NO_SHOW]: `Missed tour on ${formattedTourAt}`,
    [Tour.STATUS_SCHEDULED_TAKEN_OVER]: 'Upcoming',
    [Tour.STATUS_TECHNICAL_ISSUE]: 'Access Issue',
  };

  if (tourStatus in issueTourStatusDescriptionMap) {
    description = issueTourStatusDescriptionMap[tourStatus];
  } else if (isSelfShowing) {
    description = `${isTourInFuture ? 'Will self visit on' : 'Self visited on'} ${formattedTourAt}`;
  } else if (isOpenHouse) {
    description = `${isTourInFuture ? 'Open house on' : 'Visited open house on'} ${formattedTourAt}`;
  } else {
    description = `${isTourInFuture ? 'Will tour on ' : 'Toured on'} ${formattedTourAt}`;
  }

  return description;
}

const isTourStatusIssue = (tour) => tour?.status && ![Tour.STATUS_COMPLETED, Tour.STATUS_PENDING].includes(tour.status);

export {
  isFinished,
  getTourByDateAndAssignee,
  isPaidToursCloseToMaxBudget,
  getTourStatusDescription,
  isTourStatusIssue,
};
