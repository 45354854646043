<template>
  <ElDropdown
    placement="bottom-start"
    trigger="click"
    hide-on-click
    class="pointer align-self-center"
    @visible-change="showHamburgerRef = !showHamburgerRef"
  >
    <div
      class="hamburger"
      :class="showHamburgerRef === true ? 'opened' : 'ttt'"
    >
      <div class="upper-bun" />
      <div class="ingredients" />
      <div class="lower-bun" />
    </div>
    <ElDropdownMenu
      class="pb-0 mobile-header-dropdown"
      :class="isLoggedIn ? 'logged-in' : 'not-logged-in'"
    >
      <template v-if="isLoggedIn">
        <router-link :to="{ name: 'app.properties' }">
          <ElDropdownItem>
            Properties
          </ElDropdownItem>
        </router-link>
        <router-link :to="{ name: 'app.lease' }">
          <ElDropdownItem>
            Leasing
          </ElDropdownItem>
        </router-link>
        <router-link :to="{ name: 'app.tours' }">
          <ElDropdownItem>
            Tours
          </ElDropdownItem>
        </router-link>
        <router-link :to="{ name: 'app.condition-reports' }">
          <ElDropdownItem>
            Condition Reports
          </ElDropdownItem>
        </router-link>
        <router-link :to="{ name: 'app.profile.settings' }">
          <ElDropdownItem>
            Profile
          </ElDropdownItem>
        </router-link>
        <router-link
          v-if="user.role === Role.ACCOUNT_OWNER"
          :to="{ name: 'app.transactions' }"
        >
          <ElDropdownItem>
            Transactions
          </ElDropdownItem>
        </router-link>
        <router-link
          v-if="user.role === Role.ACCOUNT_OWNER"
          :to="{ name: 'app.billing' }"
        >
          <ElDropdownItem>
            Billing
          </ElDropdownItem>
        </router-link>
        <router-link
          v-if="user.business.device_integration_enabled_at"
          :to="{ name: 'app.devices' }"
        >
          <ElDropdownItem>
            Devices
          </ElDropdownItem>
        </router-link>
        <router-link :to="{ name: 'app.profile.business-settings' }">
          <ElDropdownItem>
            Settings
          </ElDropdownItem>
        </router-link>
        <ElDropdownItem
          class="text-danger"
          @click.native="logout"
        >
          Log out
        </ElDropdownItem>
      </template>
      <ElDropdownItem class="footer-in-header">
        <SdFooter />
      </ElDropdownItem>
    </ElDropdownMenu>
  </ElDropdown>
</template>

<script lang="ts">
import { computed, ref } from '@vue/composition-api';
import Role from '@/constants/Role';
import { redirectToSignIn } from '../../../router';

import SdFooter from './SdFooter.vue';

export default {
  name: 'SdHeader',
  components: { SdFooter },
  props: {
    bottomBorder: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, context) {
    const showHamburgerRef = ref(false);
    const isLoggedIn = context.root.$store.getters['Auth/isLoggedIn'];
    const user = context.root.$store.state.Auth.user;

    return {
      isLoggedIn,
      showHamburgerRef,
      logout,
      user,
      Role,
    };

    async function logout() {
      await context.root.$store.dispatch('Auth/logout');
      redirectToSignIn(context);
    }
  },
};
</script>

<style lang="scss">
.mobile-header-dropdown.not-logged-in {
  padding: 0;
  background: gray-color(lighter);
  &.el-popper[x-placement^="bottom"] .popper__arrow {
    border-bottom-color: gray-color(light);
  }
  &.el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: gray-color(lighter);
  }
}
.footer-in-header {
  border: 1px solid gray-color(light);
  background: gray-color(lighter);
  padding: 0;
  border-radius: 0 0 5px 5px;
}
.hamburger {
  display: inline-block;
  outline: none;
  .upper-bun,
  .ingredients,
  .lower-bun {
    width: 1.75rem;
    height: 0.25rem;
    background-color: gray-color(dark);
    margin: 6px 0;
    transition: 0.4s;
  }
  &.opened {
    .upper-bun {
      -ms-transform: rotate(-45deg) translate(-0.5rem, 0.5rem);
      -webkit-transform: rotate(-45deg) translate(-0.5rem, 0.5rem);
      transform: rotate(-45deg) translate(-0.5rem, 0.5rem);
    }
    .ingredients {
      opacity: 0;
    }
    .lower-bun {
      -ms-transform: rotate(45deg) translate(-0.4rem, -0.4rem);
      -webkit-transform: rotate(45deg) translate(-0.4rem, -0.4rem);
      transform: rotate(45deg) translate(-0.4rem, -0.4rem);
    }
  }
}
</style>
