import momentUtil from '@/utils/MomentUtil';
import { ScheduleStatuses, ScheduleConfirmationStatuses } from '@/constants/Schedule';
import Tour from '@/constants/Tour';
import { activityTypes } from '@/constants/Commons';

import { getSettingValueByKey } from './SettingUtil';

/*
* Here we have to use Schedules to show "Tours" without actual tour model
* in case it was auto.deleted/canceled or is upcoming.
*/

function getBadgeFormatStatus(type) {
  const badge = {
    label: '',
    color: 'gray',
    tooltip: undefined,
  };
  switch (type) {
    case ScheduleStatuses.STATUS_CANCELLED:
    case Tour.STATUS_CANCELLED_BY_LEAD:
      badge.label = 'Prospect cancelled';
      badge.color = 'red';
      break;
    case Tour.STATUS_CANCELLED_BY_LEAD_RESCHEDULED:
      badge.label = 'Prospect cancelled - Rescheduled';
      badge.short_label = 'Prospect Rescheduled';
      badge.color = 'red';
      break;
    case Tour.STATUS_CANCELLED_NO_LEAD_CONFIRMATION:
      badge.label = 'Prospect did not confirm';
      badge.color = 'red';
      badge.tooltip = 'We tried confirming the tour time with the prospect several times, but we did not get a response.';
      break;
    case Tour.STATUS_NO_SHOW:
      badge.label = 'Prospect no-show';
      badge.color = 'peach';
      break;
    case Tour.STATUS_CANCELLED_BY_AGENT:
    case Tour.STATUS_CANCELLED_BY_TECHNICAL_ISSUE:
      badge.label = 'Access Issue';
      badge.color = 'red';
      break;
    case Tour.STATUS_CANCELLED_BY_ADMIN:
      badge.label = 'Cancelled by Showdigs';
      badge.color = 'red';
      break;
    case Tour.STATUS_CANCELLED_BY_PM:
      badge.label = 'Cancelled by PM';
      badge.color = 'red';
      break;
    case Tour.STATUS_CANCELLED_SHOWING_STOPPED:
      badge.label = 'Cancelled - Showing stopped or changed';
      badge.color = 'red';
      break;
    case Tour.STATUS_CANCELLED_SHOWING_PAUSED:
      badge.label = 'Cancelled - Showings paused';
      badge.color = 'red';
      break;
    case Tour.STATUS_CANCELLED_SHOWING_WINDOWS_CHANGED:
      badge.label = 'Cancelled - Showing windows changed';
      badge.color = 'red';
      badge.tooltip = 'We had to cancel this showing since the showing windows coordinated with the tenant changed';
      break;
    case Tour.STATUS_CANCELLED_RESCHEDULED:
      badge.label = 'Rescheduled';
      break;
    case Tour.STATUS_TECHNICAL_ISSUE:
    case activityTypes.TOUR_TECHNICAL_ISSUE:
      badge.label = 'Access Issue';
      badge.color = 'gray';
      break;
    case ScheduleStatuses.STATUS_AUTO_DELETED:
    // case ScheduleStatuses.TOUR_NO_AGENT_FOUND:
      badge.label = 'No agent available';
      badge.color = 'orange';
      break;
    case Tour.STATUS_SCHEDULED_TAKEN_OVER:
      badge.label = 'Upcoming';
      badge.color = 'purple';
      break;
    case Tour.STATUS_PENDING:
    case Tour.STATUS_CANCELLED_SURRENDERED:
    case ScheduleStatuses.STATUS_SCHEDULED:
    case ScheduleStatuses.STATUS_PENDING:
    case ScheduleStatuses.STATUS_LOOKING_FOR_AGENT:
      badge.label = 'Upcoming';
      badge.color = 'blue';
      break;
    case Tour.STATUS_COMPLETED:
      badge.label = 'Successful';
      badge.color = 'green';
      break;
    case Tour.STATUS_TIME_OUT:
      badge.label = 'Successful - Missing Report';
      badge.color = 'green';
      break;
    // Confirmation statuses
    case ScheduleConfirmationStatuses.WAITING_FOR_CONFIRMATION:
      badge.label = 'Awaiting Confirmation';
      badge.tooltip = 'We asked the prospect to confirm their arrival';
      badge.color = 'orange';
      break;
    case ScheduleConfirmationStatuses.NOT_REQUIRED:
      badge.label = 'No confirmation required';
      badge.tooltip = 'This tour was scheduled just recently';
      badge.color = 'green';
      break;
    case ScheduleConfirmationStatuses.NOT_CONFIRMED:
      badge.label = 'Not confirmed yet';
      badge.tooltip = 'We\'ll confirm with the prospect a few hours before the tour';
      break;
    case ScheduleConfirmationStatuses.CONFIRMED:
      badge.label = 'Confirmed';
      badge.color = 'green';
      break;
    case 'requested':
      badge.label = 'Requested';
      badge.color = 'blue';
      break;
    case 'enabled':
      badge.label = 'Enabled';
      badge.color = 'green';
      break;
    case 'disabled':
      badge.label = 'Disabled';
      badge.color = 'red';
  }
  return badge;
}

function takeOverThresholdNotPassed(tourAt) {
  const setting = getSettingValueByKey('threshold_for_takeover');
  return momentUtil(tourAt, null).isAfter(momentUtil(null, null).add(setting || 180, 'minutes'));
}

function canBeCancelled(schedule) {
  return schedule.tour_status === Tour.STATUS_SCHEDULED_TAKEN_OVER;
}

function canBecomeRegularTour(schedule) {
  if (!schedule.property_market_showing_agents_available) {
    return false;
  }

  const isPendingTour = schedule.tour_status === Tour.STATUS_PENDING;
  return isPendingTour && schedule.is_self_showing && tourWithinMinTime(schedule);
}

function canBecomeSelfTour(schedule) {
  const isTakenOver = schedule.tour_status === Tour.STATUS_SCHEDULED_TAKEN_OVER;
  const isPendingTour = schedule.tour_status === Tour.STATUS_PENDING;
  const thresholdNotPassed = isTakenOver ? takeOverThresholdNotPassed(schedule.tour_at) : true;
  return (isPendingTour || isTakenOver) && schedule.self_showing_profile && !schedule.is_self_showing && thresholdNotPassed;
}

function canBeReassigned(schedule) {
  const isTakenOver = schedule.tour_status === Tour.STATUS_SCHEDULED_TAKEN_OVER;
  return isTakenOver && schedule.is_taken_over !== null && momentUtil(null, null) < momentUtil(schedule.tour_at, null);
}

function canBeRescheduled(schedule) {
  return !schedule.is_open_house && schedule.tour_status === Tour.STATUS_SCHEDULED_TAKEN_OVER;
}

function canBeSurrendered(schedule) {
  if (!schedule.property_market_showing_agents_available) {
    return false;
  }
  return tourWithinMinTime(schedule);
}

function tourWithinMinTime(schedule) {
  const minTimeToScheduleTour = getSettingValueByKey('min_time_to_schedule_tour', schedule.property_market_id);
  return momentUtil(schedule.tour_at, null).diff(momentUtil(null, null), 'minute') > minTimeToScheduleTour;
}

export {
  getBadgeFormatStatus,
  takeOverThresholdNotPassed,
  canBeReassigned,
  canBeSurrendered,
  canBeRescheduled,
  canBeCancelled,
  canBecomeRegularTour,
  canBecomeSelfTour,
};
